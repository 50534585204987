var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-6 py-6",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"d-flex justify-center",attrs:{"md1":""}},[_c('v-avatar',{attrs:{"dark":"","size":"24","color":_vm.randomColor}},[_c('span',{staticClass:"white--text text-caption"},[_vm._v(_vm._s(_vm.emailLetter))])])],1),_c('v-flex',{staticClass:"d-flex justify-start",attrs:{"md2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 pl-2 text-truncate table__text",staticStyle:{"max-width":"140px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.company))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.company))])])],1),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{staticClass:"ms-2 table__text"},[_vm._v(" "+_vm._s(_vm.firstName)+" ")])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticClass:"ms-2 table__text"},[_vm._v(" "+_vm._s(_vm.lastName)+" ")])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"pr-4 table__text"},[_vm._v(_vm._s(_vm.created_at))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center pl-6"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-truncate table__text",staticStyle:{"max-width":"110px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.email))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.email))])])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$router.push({
                    name: 'contact.customer',
                    params: { id: _vm.id },
                    query: { edit: true },
                  })}}},[_vm._v(" Edit ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                  'background-color': hover ? '#c4c9cf' : '',
                  border: hover ? 'none' : '',
                  color: hover ? 'white' : '#ffffff',
                }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                    name: 'contact.customer',
                    params: { id: _vm.id },
                  })}}},[_vm._v("View")])]}}],null,true)})],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }