var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center pt-8"},[_c('v-card',{staticClass:"rounded-lg",attrs:{"min-width":"90%","outlined":""}},[_c('h5',{staticClass:"\n        px-auto\n        mt-8\n        font-weight-bold\n        text-subtitle-1 text-center\n        primary--text\n      "},[_vm._v(" name "+_vm._s(_vm.vendorname)+" ")]),[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"px-0"},[_c('tr',{staticClass:"px-0"},[_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Hyphn Id ")]),_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Category ")])])]),_c('tbody',{staticClass:"px-1"},[_c('tr',{staticClass:"px-1"},[_c('td',{staticClass:"px-1 text-center"},[_vm._v(" "+_vm._s(_vm.hyphnId)+" ")]),_c('td',{staticClass:"px-1 text-center text-truncate",staticStyle:{"max-width":"60px"}},[_vm._v(" "+_vm._s(_vm.vendorCategory)+" ")])])])]},proxy:true}])})],_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',[[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"px-0"},[_c('tr',{staticClass:"px-0"},[_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Email ")]),_c('th',{staticClass:"px-0 text-center primary--text text-subtitle-2"},[_vm._v(" Created ")])])]),_c('tbody',{staticClass:"px-2"},[_c('tr',{staticClass:"px-2"},[_c('td',{staticClass:"px-2 text-center"},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_c('td',{staticClass:"px-2 text-center"},[_vm._v(_vm._s(_vm.createdAt))])])])]},proxy:true}])})],[_c('div',{staticClass:"d-block align-center justify-end"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-end text-capitalize",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"min-height":"fit-content"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                        'background-color': hover ? '#2bd5ae' : '',
                        border: hover ? 'none' : '',
                      }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"}},[_vm._v(" Edit ")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                        'background-color': hover ? '#c4c9cf' : '',
                        border: hover ? 'none' : '',
                        color: hover ? 'white' : '#ffffff',
                      }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                          name: 'contact.customer',
                          params: { id: _vm.id },
                          query: { vendor: true },
                        })}}},[_vm._v("View")])]}}])})],1)],1)],1)],1)]],2)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }