<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-6 py-6"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center">
          <v-flex md1 class="d-flex justify-center">
            <v-avatar dark size="24" :color="randomColor">
              <span class="white--text text-caption">{{ emailLetter }}</span>
            </v-avatar>
          </v-flex>

          <v-flex md2 class="d-flex align-center justify-start">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="mb-0 pl-2 text-truncate table__text"
                  >{{ vendorname }}</span
                >
              </template>
              <span>{{ vendorname }}</span>
            </v-tooltip>
          </v-flex>
          <v-flex md2>
            <div class="d-flex justify-start align-center pl-2">
              <div class="ms-1 table__text">
                {{ hyphnId }}
              </div>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="ms-1 table__text">{{ vendorCategory }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate table__text"
                    style="max-width: 100px"
                    >{{ email }}</span
                  >
                </template>
                <span>{{ email }}</span>
              </v-tooltip>
            </div>
          </v-flex>

          <v-flex md1>
            <div class="d-flex align-center">
              <span class="table__text">{{ createdAt }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex justify-center align-center">
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="
                    $router.push({
                      name: 'contact.customer',
                      params: { id: id },
                      query: { vendor: true, edit: true },
                    })
                  "
                  :style="{
                    'background-color': hover ? '#2bd5ae' : '',
                    border: hover ? 'none' : '',
                  }"
                  exact-path
                  depressed
                  outlined
                  dark
                  small
                  color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded"
                  style="color: var(--v-primary-base)"
                >
                  Edit
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="
                    $router.push({
                      name: 'contact.customer',
                      params: { id: id },
                      query: { vendor: true },
                    })
                  "
                  dark
                  outlined
                  depressed
                  small
                  color="primary"
                  :style="{
                    'background-color': hover ? '#c4c9cf' : '',
                    border: hover ? 'none' : '',
                    color: hover ? 'white' : '#ffffff',
                  }"
                  class="text-capitalize px-2 my-1 rounded"
                  >View</v-btn
                >
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    vendorname: {
      type: String,
    },
    hyphnId: {
      type: String,
    },

    vendorCategory: {
      type: [Number, String],
    },
    phone: {
      type: String,
    },
    email: {
      type: String,
    },
    createdAt: {
      type: [String, Date],
    },
  },
  data() {
    return {
      //
    };
  },

  computed: {
    emailLetter() {
      // return this.email.charAt(0).toUpperCase();
      return this.email.charAt(0).toUpperCase();
    },
    randomColor() {
      const colors = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
  },

  methods: {
    //   editVendor(id) {
    // this.$refs.editVendor.open();
    //   },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

v-btn .button {
  transition-duration: 0.4s;
}

v-btn .button:hover {
  background-color: #19283d;
  color: white;
}

.table__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}
</style>
